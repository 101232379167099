.home {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
    justify-content: center;
    display: flex;
  }
  .item-container {
    display: flex;
    color: white;
    background-color: white;
  }
  .item-container p {
    margin: 10px;
    font-size: 14px;
    font-family: Arial, sans-serif;
    color: #ff69b4;
  }
  .home-row {
    display: flex;
    z-index: 1;
    margin-left: 5px;
    margin-right: 5px;
  }
  .home-image {
    width: 100%;
    z-index: -1;
    margin-bottom: -150px;
    mask-image: linear-gradient(to bottom, rgba(0, 0 0, 1), rgba(0, 0, 0, 0));
  }