.header {
  height: 60px;
  background-color: #ff69b4;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
}
.header-logo {
  width: 125;
  margin-left: 0px;
  object-fit: contain;
  margin-right: 20px;
  margin-top: 18px;
  height: 42px;
}
.header-nav {
  display: flex;
  justify-content: space-evenly;
}
.header-option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
}
.header-link {
  color: white;
  text-decoration: none;
}
.header-option1 {
  font-size: 12px;
  font-weight: 400;
  font-family: Arial, sans-serif;
}
.header-option2 {
  font-size: 14px;
  font-weight: 600;
  font-family: Arial, sans-serif;
}
.search {
  display: flex;
  flex: 1;
  align-items: center;
  border-radius: 24px;
}
.search > select {
  height: 42px;
  padding: 5px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 2px 0 0 2px;
  color: #ff69b4;
}
.searchIcon {
  padding: 5px;
  height: 30px !important;
  background-color: white;
  font-size: 3.2rem !important;
  padding: 5px;
  border-radius: 0 5px 5px 0;
  color: #ff69b4;
}
.searchInput {
  height: 20px;
  padding: 10px;
  border: none;
  width: 80%;
  color: #ff69b4;
}
.searchInput :focus {
  border-color: lightsalmon;
}
.header-basket {
  display: flex;
  align-items: center;
}
.basket-count {
  margin-left: 10px;
  margin-right: 10px;
}
